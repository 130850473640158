import React from "react";
import {homePageData} from "../../commons"
import Layout from "../../components/layout"
import Seo from "../../components/seo";
import Banner from "./Components/Banner/Banner";
import ImageLoop from "./Components/ImageLoop/ImageLoop";
import ListOfCases from "./Components/ListOfCases/TargetCard";
import ServiceCard from "./Components/ServiceCard/ServiceCard";
import './index.scss'
import pathJson from "../../data/common/path.json"

const OperatePage = () => {
    const {
        bannerData,
        serviceData,
        sponsorDataFirstLine,
        sponsorDataSecondLine,
        sponsorDataThirdLine,
        targetData
    } = homePageData
    return (
        <Layout>
            <Seo title={pathJson.NEW_MEDIA_MATRIX.title}/>

            {/* 轮播 */}
            <Banner
                title={bannerData.title}
                description={bannerData.description}
                image="/pages/operate/bannerPic.png"
                background="/pages/operate/background.png"
            />

            {/* 平台服务 */}
            <section className="second_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    主要功能
                </div>
                <ServiceCard data={serviceData} className={'service'}></ServiceCard>
            </section>

            {/* 合作伙伴 */}
            <section className="third_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    客户案例
                </div>
                <div className="scrolling_animation">
                    <ImageLoop
                        list={sponsorDataFirstLine}
                        className={'roll1'}
                    />
                    <ImageLoop
                        list={sponsorDataSecondLine}
                        className={'roll2'}
                    />
                    <ImageLoop
                        list={sponsorDataThirdLine}
                        className={'roll1'}
                    />
                </div>

            </section>

            {/* 部分案列 */}
            <section className="fourth_content">
                <div style={{width: '100%', textAlign: 'center', fontSize: '24px',}}>
                    近期案例展示
                </div>

                <ListOfCases data={targetData}/>

                {/* <div className="see_more">
                <div>查看更多</div>
                <img src="/icons/arrow-down.png" alt="" />
            </div> */}
            </section>


            {/* <section className="fifth_content">
            <div className="reservation">预约咨询</div>
            <div className="demonstrate">想要获得专属演示，请与我们取得联系。</div>
            <div
            // onClick={}
            className="contact_email"
            >
            联系我们
            </div>
      </section> */}


        </Layout>
    )

}
export default OperatePage
export const Head = () => <title>新媒矩阵 | 里德笔记</title>